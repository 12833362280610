// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 50px;
}


/*
 * Global add-ons
 */

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0;
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
  display: none;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
  }
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #428bca;
}

.sidebar-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 80%;
  color: #999;
}

/*
 * Main content
 */

.main {
  padding: 10px;
}
.main .page-header {
  margin-top: 0;
}


/*
 * Placeholder dashboard ideas
 */

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}
.placeholders h4 {
  margin-bottom: 0;
}
.placeholder {
  margin-bottom: 20px;
}
.placeholder img {
  display: inline-block;
  border-radius: 50%;
}

/*
 * Misc. styles
 */

table.table-borderless tbody td, table.table-borderless tbody th {
  border: none;
}

.request-loader {
  padding-left: 10px;
}

.alert.alert-info {
  padding: 7px;
  width: 20%;
}

a[ng-click]{
  cursor: pointer;
}

/*
 * Request Details Table
 */

#requestDetails {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    padding: 3px;
  }

  table {
    font-size: 90%;
  }
}

  /*
   * JSON syntax highlighting
   */

  /* pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; } */
.string { color: #343333; }
.number { color: #ba8000; }
.boolean-true { color: #3cbe17; }
.boolean-false { color: #bc1233; }
.null { color: #2428c5; }
.key { color: #676566; font-weight: bold; }
